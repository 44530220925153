<template>
  <v-main class="Main-wrapper">
    <v-container
      id="main-layout"
      fluid
      tag="section"
    >
      <Notification />
      <router-view />
    </v-container>

    <!-- <dashboard-core-footer /> -->
  </v-main>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    Notification: () => import("@/components/sections/Notification.vue"),
    // DashboardCoreFooter: () => import('./Footer'),
  },
};
</script>
<style lang="sass" scoped>
:deep .v-main__wrap
  background-color: #F2F4F7 !important
.Main-wrapper
  padding-top: 0 !important
  height: calc(100% - 54px)
  flex: unset
  :deep .v-main__wrap
    #main-layout
      padding-bottom: 0 !important
</style>
